import * as process from 'process'

export const ZENT_ENV = process.env.ZENT_ENVIRONMENT
export const COGNITO_ID = process.env.COGNITO_ID || ''
export const COGNITO_ISSUER = process.env.COGNITO_ISSUER || ''
export const COGNITO_SECRET = process.env.COGNITO_SECRET || ''
export const AUTH_SECRET = process.env.AUTH_SECRET || ''
export const COGNITO_URL = process.env.COGNITO_URL || ''
export const API_URL = process.env.NEXT_PUBLIC_API_URL || ''

export const NEXT_PUBLIC_CONSOLE_OP_URL = process.env.NEXT_PUBLIC_CONSOLE_OP_URL || ''
export const NEXT_PUBLIC_CONSOLE_CARE_URL = process.env.NEXT_PUBLIC_CARE_OP_URL || ''
export const NEXT_PUBLIC_CONSOLE_REFUND_URL = process.env.NEXT_PUBLIC_REFUND_OP_URL || ''
export const NEXT_PUBLIC_CLOUD_FRONT_CDN_URL = process.env.NEXT_PUBLIC_CLOUD_FRONT_CDN_URL || ''
