'use client'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-use'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from './ui/breadcrumb'

const ConsoleBreadcrumb = () => {
  const [category, setCategory] = useState<string>()
  const [subMenu, setSubMenu] = useState<string>()

  const location = useLocation()
  const pathname = location.pathname

  const getList = () => {
    if (pathname?.includes('/refund-service')) {
      setCategory('refund-service')
      setSubMenu(pathname.replace('/refund-service/', ''))
    } else if (pathname?.includes('/refund') || pathname?.includes('/csform')) {
      setCategory('refund')
      const pathes = pathname.split('/')
      setSubMenu(pathes[pathes.length - 1])
    } else if (pathname?.includes('/sales')) {
      setCategory('sales')
      const pathes = pathname.split('/')
      setSubMenu(pathes[pathes.length - 1])
    } else if (pathname?.includes('/deal')) {
      const pathes = pathname.split('/')
      setCategory('deal')
      setSubMenu(pathes[2])
    } else if (pathname?.includes('/1min')) {
      setCategory('1min')
      setSubMenu(undefined)
    } else if (pathname?.includes('/corptax')) {
      setCategory('corptax')
      setSubMenu(undefined)
    } else if (pathname?.includes('/externals')) {
      setCategory('externals')
      setSubMenu(undefined)
    } else if (pathname?.includes('/fileview')) {
      setCategory('fileview')
    } else if (pathname?.includes('/admin')) {
      setCategory('admin')
      const pathes = pathname.split('/')
      setSubMenu(pathes[2])
    }
  }

  const getAdminSubMenu = () => {
    switch (subMenu) {
      case 'users':
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/admin/users">유저</BreadcrumbLink>
          </BreadcrumbItem>
        )
      case 'roles':
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/admin/roles">역할</BreadcrumbLink>
          </BreadcrumbItem>
        )
      case 'functions':
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/admin/functions">기능</BreadcrumbLink>
          </BreadcrumbItem>
        )
    }
  }

  const getRefundSubMenu = () => {
    switch (subMenu) {
      case 'small-business':
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/csform/small-business">중특감 설문결과</BreadcrumbLink>
          </BreadcrumbItem>
        )
      case 'startup-business':
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/csform/small-business">창중감 설문결과</BreadcrumbLink>
          </BreadcrumbItem>
        )
      default:
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/refund">분석상세</BreadcrumbLink>
          </BreadcrumbItem>
        )
    }
  }

  const getRefundServiceSubMenu = () => {
    switch (true) {
      case subMenu?.includes('partner/discount'):
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/refund-service/partner/discount">제휴 할인율 관리</BreadcrumbLink>
          </BreadcrumbItem>
        )
      case subMenu?.includes('emergency/notice'):
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/refund-service/emergency/notice">긴급 공지 설정</BreadcrumbLink>
          </BreadcrumbItem>
        )
      case subMenu?.includes('landing-modals'):
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/refund-service/advertisement/landing-modals">랜딩 모달 관리</BreadcrumbLink>
          </BreadcrumbItem>
        )
    }
  }

  const getSalesSubMenu = () => {
    switch (subMenu) {
      case 'channels':
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/sales/channels">영업채널 관리</BreadcrumbLink>
          </BreadcrumbItem>
        )
      case 'settlement':
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/sales/settlement">영업 정산관리</BreadcrumbLink>
          </BreadcrumbItem>
        )
      case 'salemsman':
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/sales/salesman">영업자 관리</BreadcrumbLink>
          </BreadcrumbItem>
        )
      default:
        return (
          <BreadcrumbItem>
            <BreadcrumbLink href="/sales">영업 현황</BreadcrumbLink>
          </BreadcrumbItem>
        )
    }
  }

  const getDealSubmenu = () => {
    if (subMenu) {
      return (
        <BreadcrumbItem>
          <BreadcrumbLink>상세</BreadcrumbLink>
        </BreadcrumbItem>
      )
    } else {
      return ''
    }
  }

  useEffect(() => {
    getList()
  }, [location])

  return (
    <div className="relative print:hidden">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          {category === 'refund' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/refund">종소세 경정청구</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              {getRefundSubMenu()}
            </>
          )}
          {category === 'sales' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink>영업관리</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              {getSalesSubMenu()}
            </>
          )}
          {category === 'deal' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/deal">환급</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />

              {getDealSubmenu()}
            </>
          )}
          {category === '1min' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/1min">1분 환급</BreadcrumbLink>
              </BreadcrumbItem>
            </>
          )}
          {category === 'corptax' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/corptax">법인세 경정청구</BreadcrumbLink>
              </BreadcrumbItem>
            </>
          )}
          {category === 'externals' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/externals">외부 링크</BreadcrumbLink>
              </BreadcrumbItem>
            </>
          )}
          {category === 'fileview' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/fileview">미리보기</BreadcrumbLink>
              </BreadcrumbItem>
            </>
          )}
          {category === 'refund-service' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/">환급 서비스</BreadcrumbLink>
              </BreadcrumbItem>
              {subMenu && (
                <>
                  <BreadcrumbSeparator />
                  {getRefundServiceSubMenu()}
                </>
              )}
            </>
          )}
          {category === 'admin' && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>admin</BreadcrumbItem>
              <BreadcrumbSeparator />
              {getAdminSubMenu()}
            </>
          )}
        </BreadcrumbList>
      </Breadcrumb>
    </div>
  )
}
export default ConsoleBreadcrumb
